import React from 'react';
import { graphql } from 'gatsby';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import Project from '../components/project';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query ProjectTemplateQuery($id: String!, $language: String!) {
    sampleProject: sanityProject(_id: { eq: $id }) {
      title {
        en
        bg
      }
      slug {
        current
      }
      category {
        title {
          bg
          en
        }
      }
      description {
        en
        bg
      }
      images {
        asset {
          url
        }
        caption
      }
      body {
        en {
          children {
            text
            marks
          }
          list
          style
        }
        bg {
          children {
            marks
            text
          }
          list
          style
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ProjectTemplate = (props) => {
  const { data, errors } = props;
  const project = data && data.sampleProject;
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {project && <SEO title={project.title[language] || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} />}
    </Layout>
  );
};

export default ProjectTemplate;
