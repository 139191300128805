import React from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import Container from './container';

function Project({ body, title, category, images, description }) {
  const { language } = React.useContext(I18nextContext);
  return (
    <article className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
      {/* Image gallery */}
      <Tab.Group as="div" className="flex flex-col-reverse">
        {/* Image selector */}
        <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
          <Tab.List className="grid grid-cols-4 gap-6">
            {images.map((image, index) => (
              <Tab
                key={index}
                className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
              >
                {({ selected }) => (
                  <>
                    <span className="sr-only">{image.caption}</span>
                    <span className="absolute inset-0 rounded-md overflow-hidden">
                      <img
                        src={image.asset.url}
                        alt=""
                        className="w-full h-full object-center object-cover"
                      />
                    </span>
                    <span
                      className={classNames(
                        selected ? 'ring-indigo-500' : 'ring-transparent',
                        'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </Tab>
            ))}
          </Tab.List>
        </div>

        <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
          {images.map((image, index) => (
            <Tab.Panel key={index}>
              <img
                src={image.asset.url}
                alt={image.caption}
                className="w-full h-full object-center object-cover sm:rounded-lg"
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Container>
        <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
          <nav aria-label="Breadcrumb">
            <ol className="flex items-center space-x-2">
              <li>
                <div className="flex items-center text-sm">
                  <a
                    href={category.title[language]}
                    className="font-medium text-gray-500 hover:text-gray-900"
                  >
                    {category.title[language]}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
            {title[language]}
          </h1>
          <p className="mt-1 text-sm italic text-gray-500">
            {description[language]}
          </p>
          <div className="mt-8 border-t border-gray-200 pt-8">
            <div className="mt-4 prose prose-sm text-gray-900">
              <ul>
                {body[language].map((item, index) => (
                  <li key={index}>{item.children[0].text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </article>
  );
}

export default Project;
